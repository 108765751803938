"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.additionalWordsBySlug = void 0;
exports.additionalWordsBySlug = {
    'Chi_veschi': [
        { index: 0, additionalWords: ['мама', 'мамы'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 1, additionalWords: ['маша', 'маши'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 2, additionalWords: ['папа', 'папы'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 3, additionalWords: ['саша', 'саши'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 4, additionalWords: ['ваня', 'вани'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 5, additionalWords: ['коля', 'коли'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 6, additionalWords: ['катя', 'кати'], sliceCount: 3, skip: 0, correctWord: 'котик' },
    ],
    'Plural_verbs_2': [
        { index: 0, additionalWords: ['сидят'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 1, additionalWords: ['поют'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 2, additionalWords: ['летят'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 3, additionalWords: ['едет'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 4, additionalWords: ['танцует', 'танцы'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 5, additionalWords: ['ест', 'едут', 'еда'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 6, additionalWords: ['бежит', 'бег'], sliceCount: 3, skip: 0, correctWord: 'котик' },
    ],
    'chasti-tela': [
        { 'index': 0, 'word': 'Рука', 'additionalWords': ['руки', "рука"], 'sliceCount': 2, 'skip': 0, 'correctWord': 'рука' },
        { 'index': 1, 'word': 'Руки', 'additionalWords': ['рука', "руки"], 'sliceCount': 2, 'skip': 2, 'correctWord': 'руки' },
        { 'index': 2, 'word': 'Нога', 'additionalWords': ['ноги', "нога"], 'sliceCount': 2, 'skip': 4, 'correctWord': 'нога' },
        { 'index': 3, 'word': 'Ноги', 'additionalWords': ['нога', "ноги"], 'sliceCount': 2, 'skip': 6, 'correctWord': 'ноги' },
        { 'index': 4, 'word': 'туловище', 'additionalWords': ['туловище'], 'sliceCount': 1, 'skip': 8, 'correctWord': 'туловище' },
        { 'index': 5, 'word': 'голова', 'additionalWords': ['голова'], 'sliceCount': 1, 'skip': 9, 'correctWord': 'голова' },
        { 'index': 6, 'word': 'шея', 'additionalWords': ['шея'], 'sliceCount': 1, 'skip': 10, 'correctWord': 'шея' },
        { 'index': 7, 'word': 'Плечо', 'additionalWords': ['плечи', "плечо"], 'sliceCount': 2, 'skip': 11, 'correctWord': 'плечо' },
        { 'index': 8, 'word': 'Плечи', 'additionalWords': ['плечо', "плечи"], 'sliceCount': 2, 'skip': 13, 'correctWord': 'плечи' },
        { 'index': 9, 'word': 'Локоть', 'additionalWords': ['локти', "локоть"], 'sliceCount': 2, 'skip': 15, 'correctWord': 'локоть' },
        { 'index': 10, 'word': 'Локти', 'additionalWords': ['локоть', "локти"], 'sliceCount': 2, 'skip': 17, 'correctWord': 'локти' },
        { 'index': 11, 'word': 'Колено', 'additionalWords': ['колени', "колено"], 'sliceCount': 2, 'skip': 19, 'correctWord': 'колено' },
        { 'index': 12, 'word': 'Колени', 'additionalWords': ['колено', "колени"], 'sliceCount': 2, 'skip': 21, 'correctWord': 'колени' }
    ],
    'vklyuchit_chto': [
        {
            "index": 0,
            "additionalWords": ['утюга', 'утюгу', 'утюги', 'утюгом'],
            "sliceCount": 4,
            "skip": 0,
            "correctWord": 'утюг'
        },
        {
            "index": 1,
            "additionalWords": ['лампа', 'лампой', 'лампы', 'лампе'],
            "sliceCount": 4,
            "skip": 4,
            "correctWord": 'лампу'
        },
        {
            "index": 2,
            "additionalWords": ['пылесосу', 'пылесоса', 'пылесосом'],
            "sliceCount": 3,
            "skip": 8,
            "correctWord": 'пылесос'
        },
        {
            "index": 3,
            "additionalWords": ['телевизора', 'телевизором', 'телевизору', 'телевизоры'],
            "sliceCount": 4,
            "skip": 11,
            "correctWord": 'телевизор'
        }
    ],
    'vyberi_predmet_1': [
        {
            "index": 0,
            "additionalWords": ["голом", "гола", "голы", "мячом", "мяча"],
            "sliceCount": 5,
            "skip": 0,
            "correctWord": "гол"
        },
        {
            "index": 1,
            "additionalWords": ["окна", "окном", "окон"],
            "sliceCount": 3,
            "skip": 5,
            "correctWord": "окно"
        },
        {
            "index": 2,
            "additionalWords": ["рубашки", "рубашка", "рубашкой", "рубашек"],
            "sliceCount": 4,
            "skip": 8,
            "correctWord": "рубашку"
        },
        {
            "index": 3,
            "additionalWords": ["щука", "щуки"],
            "sliceCount": 2,
            "skip": 12,
            "correctWord": "щуку"
        },
        {
            "index": 3,
            "additionalWords": ["зуб", "зубами", "зубов"],
            "sliceCount": 3,
            "skip": 12,
            "correctWord": "зубы"
        },
        {
            "index": 4,
            "additionalWords": ["звоздем", "звозди"],
            "sliceCount": 2,
            "skip": 15,
            "correctWord": "гвоздь"
        },
        {
            "index": 5,
            "additionalWords": ["книга", "книгой", "книги"],
            "sliceCount": 3,
            "skip": 17,
            "correctWord": "книгу"
        },
        {
            "index": 6,
            "additionalWords": ["платья", "платью"],
            "sliceCount": 2,
            "skip": 20,
            "correctWord": "платье"
        },
        {
            "index": 7,
            "additionalWords": ["бабочка", "бабочки", "бабочкой"],
            "sliceCount": 3,
            "skip": 22,
            "correctWord": "бабочку"
        },
        {
            "index": 8,
            "additionalWords": ["картошка", "картошки", "картошкой"],
            "sliceCount": 3,
            "skip": 25,
            "correctWord": "картошку"
        }
    ],
    'vyberi_predmet_2': [
        {
            "index": 0,
            "additionalWords": ["книга", "книгой", "книги"],
            "sliceCount": 3,
            "skip": 0,
            "correctWord": "книгу"
        },
        {
            "index": 1,
            "additionalWords": ["сыра", "сыру", "сыром", "сыры"],
            "sliceCount": 4,
            "skip": 3,
            "correctWord": "сыр"
        },
        {
            "index": 2,
            "additionalWords": ["бабушка", "бабушки", "бабушкой"],
            "sliceCount": 3,
            "skip": 7,
            "correctWord": "бабушку"
        },
        {
            "index": 3,
            "additionalWords": ["ягодами", "ягод", "ягоду"],
            "sliceCount": 3,
            "skip": 10,
            "correctWord": "ягоды"
        },
        {
            "index": 4,
            "additionalWords": ["шарфом", "шарфа", "шарфы"],
            "sliceCount": 3,
            "skip": 13,
            "correctWord": "шарф"
        },
        {
            "index": 5,
            "additionalWords": ["газета", "газетой", "газеты"],
            "sliceCount": 3,
            "skip": 16,
            "correctWord": "газету"
        },
        {
            "index": 6,
            "additionalWords": ["батона", "батоны", "батоном"],
            "sliceCount": 3,
            "skip": 19,
            "correctWord": "батон"
        },
        {
            "index": 7,
            "additionalWords": ["дедушка", "дедушки", "дедушкой"],
            "sliceCount": 3,
            "skip": 22,
            "correctWord": "дедушку"
        },
        {
            "index": 8,
            "additionalWords": ["рыб", "рыбами", "рыбу"],
            "sliceCount": 3,
            "skip": 25,
            "correctWord": "рыбы"
        },
        {
            "index": 9,
            "additionalWords": ["шапка", "шапкой", "шапки"],
            "sliceCount": 3,
            "skip": 28,
            "correctWord": "шапку"
        }
    ],
    'vyberi_predmet_3': [
        {
            "index": 0,
            "additionalWords": ["стакана", "стаканы", "стаканом", "стаканы"],
            "sliceCount": 4,
            "skip": 0,
            "correctWord": "стакан"
        },
        {
            "index": 1,
            "additionalWords": ["пуговица", "пуговицы", "пуговичной"],
            "sliceCount": 3,
            "skip": 4,
            "correctWord": "пуговицу"
        },
        {
            "index": 2,
            "additionalWords": ["забора", "забору", "забором"],
            "sliceCount": 3,
            "skip": 7,
            "correctWord": "забор"
        },
        {
            "index": 3,
            "additionalWords": ["велосипеда", "велосипедом"],
            "sliceCount": 2,
            "skip": 10,
            "correctWord": "велосипед"
        },
        {
            "index": 4,
            "additionalWords": ["змея", "змеи", "змеем"],
            "sliceCount": 3,
            "skip": 12,
            "correctWord": "змею"
        },
        {
            "index": 5,
            "additionalWords": ["чашкой", "чашка", "чашке"],
            "sliceCount": 3,
            "skip": 15,
            "correctWord": "чашку"
        },
        {
            "index": 6,
            "additionalWords": ["тёсьма", "тёсьмой", "тёсьмы"],
            "sliceCount": 3,
            "skip": 18,
            "correctWord": "тёщу"
        },
        {
            "index": 7,
            "additionalWords": ["крыша", "крыше", "крышой"],
            "sliceCount": 3,
            "skip": 21,
            "correctWord": "крышу"
        },
        {
            "index": 8,
            "additionalWords": ["кольца", "кольцом", "колец"],
            "sliceCount": 3,
            "skip": 24,
            "correctWord": "кольцо"
        },
        {
            "index": 9,
            "additionalWords": ["медведь", "медведю", "медведем"],
            "sliceCount": 3,
            "skip": 27,
            "correctWord": "медведь"
        }
    ],
    // 'launch_glagol': [
    //     {
    //         "index": 0,
    //         "additionalWords": [],
    //         "sliceCount": 0,
    //         "skip": 0,
    //         "correctWord": "слушает"
    //     },
    //     {
    //         "index": 1,
    //         "additionalWords": [],
    //         "sliceCount": 0,
    //         "skip": 1,
    //         "correctWord": ['шел', 'лил']
    //     },
    //     {
    //         "index": 2,
    //         "additionalWords": [],
    //         "sliceCount": 0,
    //         "skip": 2,
    //         "correctWord": ['светит', 'вышло']
    //     },
    //     {
    //         "index": 3,
    //         "additionalWords": [],
    //         "sliceCount": 0,
    //         "skip": 3,
    //         "correctWord": "лечит"
    //     },
    //     {
    //         "index": 4,
    //         "additionalWords": [],
    //         "sliceCount": 0,
    //         "skip": 4,
    //         "correctWord": "строят"
    //     },
    //     {
    //         "index": 5,
    //         "additionalWords": [],
    //         "sliceCount": 0,
    //         "skip": 5,
    //         "correctWord": "играть"
    //     },
    //     {
    //         "index": 6,
    //         "additionalWords": [],
    //         "sliceCount": 0,
    //         "skip": 6,
    //         "correctWord": ['проходит', 'идет']
    //     },
    //     {
    //         "index": 7,
    //         "additionalWords": [],
    //         "sliceCount": 0,
    //         "skip": 7,
    //         "correctWord": ['идем', 'поедем']
    //     },
    //     {
    //         "index": 8,
    //         "additionalWords": [],
    //         "sliceCount": 0,
    //         "skip": 8,
    //         "correctWord": "закажем"
    //     },
    //     {
    //         "index": 9,
    //         "additionalWords": [],
    //         "sliceCount": 0,
    //         "skip": 9,
    //         "correctWord": ['привез', 'принес', 'доставил']
    //     }
    // ]
};
