"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.additionalWordsBySlug = void 0;
exports.additionalWordsBySlug = {
    'U_kogo_kto': [
        { index: 0, additionalWords: ['тепята', 'телёнки', 'телёнок'], sliceCount: 3, skip: 0, correctWord: 'телёнок' },
        { index: 1, additionalWords: ['птенчик', 'птенец', 'птицы', 'птица', 'птенцы'], sliceCount: 5, skip: 3, correctWord: 'птенцы' },
        { index: 2, additionalWords: ['тигрёнок', 'тигр', 'тигры', 'тигрица'], sliceCount: 4, skip: 8, correctWord: 'тигрёнок' },
        { index: 3, additionalWords: ['утята', 'утёнок', 'утка', 'уточка', 'утки'], sliceCount: 5, skip: 12, correctWord: 'утята' },
        { index: 4, additionalWords: ['зайка', 'заец', 'зайчиха', 'зайцы', 'зайчонок'], sliceCount: 5, skip: 17, correctWord: 'зайчонок' },
        { index: 5, additionalWords: ['цып', 'цыплята', 'цыплёнки', 'цыпленок', 'цыплёнок'], sliceCount: 5, skip: 22, correctWord: 'цыплёнок' },
        { index: 6, additionalWords: ['лисы', 'лиса', 'лисята', 'лисёнок'], sliceCount: 4, skip: 27, correctWord: 'лисёнок' },
    ],
    'Plural': [
        { 'index': 0, 'additionalWords': ['девочка', 'девочки'], 'sliceCount': 2, 'skip': 0, 'correctWord': 'девочки' },
        { 'index': 1, 'additionalWords': ['гриб', 'грибы', 'грибок'], 'sliceCount': 3, 'skip': 2, 'correctWord': 'грибы' },
        { 'index': 2, 'additionalWords': ['игрушки', 'игрушка'], 'sliceCount': 2, 'skip': 5, 'correctWord': 'игрушки' },
        { 'index': 3, 'additionalWords': ['книга', 'книги'], 'sliceCount': 2, 'skip': 7, 'correctWord': 'книги' },
        { 'index': 4, 'additionalWords': ['вёдра', 'ведро'], 'sliceCount': 2, 'skip': 9, 'correctWord': 'вёдра' },
        { 'index': 5, 'additionalWords': ['глаза', 'глаз'], 'sliceCount': 2, 'skip': 11, 'correctWord': 'глаза' },
        { 'index': 6, 'additionalWords': ['птенцы', 'птенец'], 'sliceCount': 2, 'skip': 13, 'correctWord': 'птенцы' },
        { 'index': 7, 'additionalWords': ['стулья', 'стул'], 'sliceCount': 2, 'skip': 15, 'correctWord': 'стулья' },
        { 'index': 8, 'additionalWords': ['листья', 'лист', 'листик'], 'sliceCount': 3, 'skip': 17, 'correctWord': 'листья' },
        { 'index': 9, 'additionalWords': ['окна', 'окно'], 'sliceCount': 2, 'skip': 20, 'correctWord': 'окна' },
        { 'index': 10, 'additionalWords': ['рукава', 'рукав'], 'sliceCount': 2, 'skip': 22, 'correctWord': 'рукава' },
        { 'index': 11, 'additionalWords': ['пни', 'пень'], 'sliceCount': 2, 'skip': 24, 'correctWord': 'пни' },
        { 'index': 12, 'additionalWords': ['перья', 'перо'], 'sliceCount': 2, 'skip': 26, 'correctWord': 'перья' },
        { 'index': 13, 'additionalWords': ['уши', 'ухо'], 'sliceCount': 2, 'skip': 28, 'correctWord': 'уши' }
    ],
    'Nazovi_Laskovo': [
        { index: 0, additionalWords: ['кот', 'коты', 'котик'], sliceCount: 3, skip: 0, correctWord: 'котик' },
        { index: 1, additionalWords: ['дом', 'дома', 'домы', 'домик'], sliceCount: 4, skip: 3, correctWord: 'домик' },
        { index: 2, additionalWords: ['том', 'тома', 'томы', 'томик'], sliceCount: 4, skip: 7, correctWord: 'томик' },
        { index: 3, additionalWords: ['мёд', 'мёда', 'медок'], sliceCount: 3, skip: 11, correctWord: 'медок' },
        { index: 4, additionalWords: ['дуб', 'дубы', 'дуба', 'дубок'], sliceCount: 4, skip: 14, correctWord: 'дубок' },
        { index: 5, additionalWords: ['остров', 'острова', 'островок'], sliceCount: 3, skip: 18, correctWord: 'островок' },
        { index: 6, additionalWords: ['конь', 'кони', 'коня', 'конёк'], sliceCount: 4, skip: 21, correctWord: 'конёк' },
        { index: 7, additionalWords: ['пень', 'пени', 'пенёк'], sliceCount: 3, skip: 25, correctWord: 'пенёк' },
        { index: 8, additionalWords: ['лук', 'луки', 'лучи', 'лучок'], sliceCount: 4, skip: 28, correctWord: 'лучок' },
        { index: 9, additionalWords: ['палец', 'пальцы', 'пальчик'], sliceCount: 3, skip: 32, correctWord: 'пальчик' }
    ],
    'Odin_Mnogo': [
        { 'index': 0, 'additionalWords': ['нога', 'ног', 'ноги'], 'sliceCount': 3, 'skip': 0, 'correctWord': 'ноги' },
        { 'index': 1, 'additionalWords': ['кони', 'конь'], 'sliceCount': 2, 'skip': 3, 'correctWord': 'кони' },
        { 'index': 2, 'additionalWords': ['дыня', 'дыни'], 'sliceCount': 2, 'skip': 5, 'correctWord': 'дыни' },
        { 'index': 3, 'additionalWords': ['мак', 'маки'], 'sliceCount': 2, 'skip': 7, 'correctWord': 'маки' },
        { 'index': 4, 'additionalWords': ['тень', 'тени'], 'sliceCount': 2, 'skip': 9, 'correctWord': 'тени' },
        { 'index': 5, 'additionalWords': ['ель', 'ели'], 'sliceCount': 2, 'skip': 11, 'correctWord': 'ели' },
        { 'index': 6, 'additionalWords': ['муха', 'мух', 'мухи'], 'sliceCount': 3, 'skip': 13, 'correctWord': 'мухи' } // 11 + 2
    ],
    'Odin_Mnogo_2': [
        { 'index': 0, 'additionalWords': ['дуб', 'дубы'], 'sliceCount': 2, 'skip': 0, 'correctWord': 'дубы' },
        { 'index': 1, 'additionalWords': ['нота', 'нот', 'ноты'], 'sliceCount': 3, 'skip': 2, 'correctWord': 'ноты' },
        { 'index': 2, 'additionalWords': ['кот', 'коты'], 'sliceCount': 2, 'skip': 5, 'correctWord': 'коты' },
        { 'index': 3, 'additionalWords': ['кит', 'киты'], 'sliceCount': 2, 'skip': 7, 'correctWord': 'киты' },
        { 'index': 4, 'additionalWords': ['ивы', 'ива'], 'sliceCount': 2, 'skip': 9, 'correctWord': 'ивы' },
        { 'index': 5, 'additionalWords': ['липы', 'липа'], 'sliceCount': 2, 'skip': 11, 'correctWord': 'липы' }
    ],
};
