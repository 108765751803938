"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.generateInitialState = exports.shuffleArray = void 0;
const shuffleArray = (array) => {
    const newArray = [...array];
    for (let i = 0; i < newArray.length; i++) {
        const j = Math.floor(Math.random() * (i + 1)); // Генерируем случайный индекс
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]]; // Меняем местами элементы
    }
    return newArray;
};
exports.shuffleArray = shuffleArray;
const generateInitialState = (length) => {
    return Array.from({ length }, (_, index) => ({
        isCorrect: false,
        index: index + 1,
        isChecked: false
    }));
};
exports.generateInitialState = generateInitialState;
