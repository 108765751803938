"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.initialButtonState = void 0;
exports.initialButtonState = [{ isCorrect: false, index: 1, isChecked: false }, {
        isCorrect: false,
        index: 2,
        isChecked: false
    }, { isCorrect: false, index: 3, isChecked: false }, {
        isCorrect: false,
        index: 4,
        isChecked: false
    }, { isCorrect: false, index: 5, isChecked: false }, {
        isCorrect: false,
        index: 6,
        isChecked: false
    }, { isCorrect: false, index: 7, isChecked: false }, {
        isCorrect: false,
        index: 8,
        isChecked: false
    }, { isCorrect: false, index: 9, isChecked: false }, { isCorrect: false, index: 10, isChecked: false }];
